import React, { useState, useContext, useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CLabel,
  CSelect,
} from "@coreui/react";
import Stepper from "bs-stepper";

import { BreadcrumbContext } from "../../../../../components/Breadcrumb";
import { AuthContext } from "../../../../../components/FirebaseAuth";
import {
  CloudFunctions,
  FirebaseAuth,
  LogAnalyticsEvent,
} from "../../../../../components/FirebaseAuth/firebase";
import { Field, Input } from "../../../../../components/Form";
import Step from "../../../../../components/Stepper/Step";
import StepContent from "../../../../../components/Stepper/StepContent";
import SpecsButtonGroup from "./SpecsButtonGroup";
import Result from "./Result";
import Features from "./Features";

import {
  addDays,
  addHours,
  addMinutes,
  CLUSTER_VERSIONS,
  EXPIRY_OPTIONS,
  ACCOUNT_PLANS,
  RESOURCES_FOR_PLAN,
} from "../../../../../constants/clusters";

const NewCluster = () => {
  const title = "Create New Cluster";
  const { userData, authUser } = useContext(AuthContext);
  const [newClusterID, setNewClusterID] = useState(null);
  const [ClusterName, setClusterName] = useState({
    hasError: false,
    error: null,
    value: null,
  });
  const [resources, setResources] = useState(RESOURCES_FOR_PLAN);

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFeatureInputs, setSelectedFeatureInputs] = useState([]);
  const [formValues, setFormValues] = useState([]);

  const [ClusterDescription, setClusterDescription] = useState(null);

  const [clusterSizeIndex, setClusterSizeIndex] = useState();

  const [currentStep, setCurrentStep] = useState(0);
  /* eslint-disable */
  const [successMessage, setSuccessMessage] = useState(null);
  const [showQuotaLimit, setShowQuotaLimit] = useState({
    status: false,
    messages: [],
  });
  const [error, setError] = useState({
    status: false,
    messages: [],
  });

  const [inSubmit, setInSubmit] = useState(false);

  const [redirect, setRedirect] = useState(null);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [clusterResource, getClusterResource] = useState([]);

  const [clusters, setClusters] = useState([]);
  const [curCluster, setCurCluster] = useState([]);
  const [size, setSize] = useState();
  const [currentAccountUsed, setCurrentAccountUsed] = useState({
    accountId: userData.currentAccount.id,
    sizes: {
      RAM: 0,
      CPU: 0,
      STORAGE: 0,
    },
  });

  useEffect(() => {
    const clustersRef = FirebaseAuth.firestore().collection("clusters");

    let items = [];
    clustersRef.get().then((clustersSnapshots) => {
      clustersSnapshots.forEach((cluster) => {
        items.push({
          clusterId: cluster.id,
          accountId: cluster.data().accountId,
          size: cluster.data().size,
        });
      });
      setClusters(items);
      const currentCluster = items.filter(
        (x) => x.accountId === userData.currentAccount.id
      );
      setCurCluster(currentCluster);

      if (currentCluster.length) {
        let RAM = 0;
        let CPU = 0;
        let SSD = 0;
        currentCluster.map((clr) => {
          // eslint-disable-line
          const sizeInfo = clusterResource.find((x) => x.id === clr.size);
          if (sizeInfo) {
            RAM += sizeInfo.RAM;
            CPU += sizeInfo.CPU;
            SSD += sizeInfo.SSD;
          }
        });

        setCurrentAccountUsed((prevState) => ({
          ...prevState,
          sizes: {
            RAM: RAM,
            CPU: CPU,
            STORAGE: SSD,
          },
        }));
      }
    });
  }, [ClusterName]);

  const toggleFeatureSelection = (ftrId, inputs, formOptions) => {
    if (inputs) {
      setSelectedFeatureInputs((prevState) => [
        ...prevState.filter((x) => Object.keys(x)[0] !== ftrId),
        {
          [ftrId]: inputs,
        },
      ]);
      setFormValues((prevState) => [
        ...prevState.filter((x) => Object.keys(x)[0] !== ftrId),
        {
          [ftrId]: formOptions,
        },
      ]);
    }
    let curFtr = selectedFeatures.find((x) => x === ftrId);
    if (curFtr) {
      //remove if already exists
      setSelectedFeatures((prevState) => [
        ...prevState.filter((x) => x !== ftrId),
      ]);
    } else {
      //add if doesnt exist
      setSelectedFeatures((prevState) => [...prevState, ftrId]);
    }
    // select when save
    if (formOptions) {
      //add if doesnt exist
      setSelectedFeatures((prevState) => [...prevState, ftrId]);
    }
  };

  const calcExpiry = (selectedValue, options) => {
    const currentOption = options.find((x) => x.label === selectedValue);

    switch (currentOption.format) {
      case "NEVER":
        return null;
      case "DAYS":
        return addDays(new Date(), currentOption.value);
      case "HOURS":
        return addHours(new Date(), currentOption.value);
      case "MINS":
        return addMinutes(new Date(), currentOption.value);
      default:
        return null;
    }
  };

  const CURRENT_PLAN_EXPIRY_OPTIONS = useMemo(() => {
    let options = [...EXPIRY_OPTIONS.ALL];
    if (userData.currentAccount.plan !== ACCOUNT_PLANS["starter"]) {
      options.push(...EXPIRY_OPTIONS.ALL_PAID);
    }

    options.push(
      ...EXPIRY_OPTIONS.PER_PLAN.find(
        (x) => x.plan === userData.currentAccount.plan
      ).options
    );

    return options.sort(
      (a, b) => calcExpiry(a.label, options) - calcExpiry(b.label, options)
    );
  }, [userData.currentAccount.plan]);

  const [expiryOption, setExpiryOption] = useState(
    CURRENT_PLAN_EXPIRY_OPTIONS.find((x) => x.default).label
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);
    const newName = document.getElementById("newClusterName").value;
    const newDesc = document.getElementById("newClusterDesc").value;

    if (!newName) return null;

    setInSubmit(true);
    setError({ status: false, messages: [] });

    const featuresList = [
      ...selectedFeatures.map((ftr) => {
        return {
          [ftr]: {
            requested: true,
            inputs:
              selectedFeatureInputs.filter(
                (x) => Object.keys(x)[0] === ftr
              )?.[0]?.[ftr] || {},
            exec_status: "PENDING",
          },
        };
      }),
    ];

    const newClusterDetails = {
      accountId: userData.currentAccount.id,
      createdDate: new Date(),
      name: newName,
      owner: authUser.user.uid,
      description: newDesc,
      version: document.getElementById("selectVersion").value,
      expiry: calcExpiry(expiryOption, CURRENT_PLAN_EXPIRY_OPTIONS),
      size:
        (clusterSizeIndex === 0 && "SMALL") ||
        (clusterSizeIndex === 1 && "MEDIUM") ||
        (clusterSizeIndex === 2 && "REGULAR") ||
        (clusterSizeIndex === 3 && "LARGE") ||
        (clusterSizeIndex === 4 && "GRAND"),
      features: {
        ...featuresList.reduce((a, b) => Object.assign(a, b), {}),
      },
    };

    const clusterToAdd = {
      ...newClusterDetails,
      newRecord: true, // used in firebaseListener controller
    };

    const validateCluster = CloudFunctions.httpsCallable("validateNewCluster");
    validateCluster(clusterToAdd).then(async (response) => {
      if (response.data?.status) {
        //creation of cluster is valid
        const clustersRef = FirebaseAuth.firestore().collection("clusters");

        const existingClusterName = await clustersRef
          .where("name", "==", newName)
          .where("accountId", "==", userData.currentAccount.id)
          .get();
        if (existingClusterName.size > 0) {
          setError({
            status: true,
            messages: [
              `'${newName}' exists. Please try with a different name.`,
            ],
          });
        } else {
          const response = await clustersRef.add(newClusterDetails);

          LogAnalyticsEvent("cluster_created", {
            clusterName: newName,
            datacenter: userData.currentAccount.datacenter,
            accountId: userData.currentAccount.id,
            owner: authUser.user.uid,
            user_name: FirebaseAuth.auth().currentUser.displayName || "",
            user_email: FirebaseAuth.auth().currentUser.email || "",
          });

          setShowQuotaLimit({ status: false, messages: [] });
          setNewClusterID(response.id);
          setSuccessMessage("Cluster added in database.");
        }
      } else {
        setShowQuotaLimit({ status: true, messages: response.data.messages });
      }
      setInSubmit(false);
      document.getElementById("newClusterName").value = ""; //clear the text area value
      document.getElementById("newClusterDesc").value = ""; //clear the text area value
      moveNextStep();
    });
  };

  const getClusterResources = async () => {
    const clusterResourceRef = await FirebaseAuth.firestore()
      .collection("clusterResources")
      .get();
    clusterResourceRef.forEach((option) => {
      if (option.data().id) {
        getClusterResource((prevState) => [...prevState, option.data()]);
      }
    });
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/home",
        text: "Home",
        active: false,
      },
      {
        to: "/account/" + userData?.currentAccount?.id + "/",
        text: userData?.currentAccount?.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [userData, setBreadcrumb, title]);

  useEffect(() => {
    moveNextStep();
    getClusterResources();
  }, []);

  const moveNextStep = () => {
    var stepper = new Stepper(document.querySelector("#stepper1"));

    stepper.to(currentStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const movePreviousStep = () => {
    var stepper = new Stepper(document.querySelector("#stepper1"));

    stepper.to(currentStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const handleCloseForm = () => {
    setRedirect("/account/" + userData?.currentAccount?.id + "/");
    // var stepper = new Stepper(document.querySelector("#stepper1"));

    // stepper.to(1);
    // setCurrentStep(1);
  };

  return (
    <>
      {redirect === null && (
        <div className="container-fluid">
          <div className="animated fadeIn">
            <div className="card">
              <div className="card-header">{title}</div>
              <div className="card-body">
                <StepperComponent
                  {...{
                    handleFormSubmit,
                    ClusterName,
                    setClusterName,
                    ClusterDescription,
                    setClusterDescription,
                    inSubmit,
                    currentStep,
                    moveNextStep,
                    movePreviousStep,
                    handleCloseForm,
                    clusterSizeIndex,
                    setClusterSizeIndex,
                    selectedFeatures,
                    toggleFeatureSelection,
                    selectedFeatureInputs,
                    formValues,
                    newClusterID,
                    showQuotaLimit,
                    error,
                    CURRENT_PLAN_EXPIRY_OPTIONS,
                    expiryOption,
                    setExpiryOption,
                    clusterResource,
                    currentAccountUsed,
                    curCluster,
                    userData,
                    resources,
                  }}
                />
              </div>
              <div className="card-footer text-success text-center">
                Datacenter: {userData.currentAccount.datacenter}
              </div>
            </div>
          </div>
        </div>
      )}
      {redirect !== null && <Redirect to={redirect}></Redirect>}
    </>
  );
};

const StepperComponent = ({
  handleFormSubmit,

  ClusterName,
  setClusterName,
  ClusterDescription,
  setClusterDescription,
  inSubmit,
  currentStep,
  moveNextStep,
  movePreviousStep,
  handleCloseForm,
  clusterSizeIndex,
  setClusterSizeIndex,
  selectedFeatures,
  toggleFeatureSelection,
  selectedFeatureInputs,
  formValues,
  newClusterID,
  showQuotaLimit,
  error,
  CURRENT_PLAN_EXPIRY_OPTIONS,
  expiryOption,
  setExpiryOption,
  clusterResource,
  currentAccountUsed,
  curCluster,
  userData,
  resources,
}) => (
  <div id="stepper1" className="bs-stepper">
    <div className="bs-stepper-header stepperHeader">
      <Step
        dataTarget="#specsDiv"
        stepperNumber="1"
        stepperText="Cluster Specs"
      />
      <div className="line stepperLine"></div>
      <Step
        dataTarget="#featuresDiv"
        stepperNumber="2"
        stepperText="Addons"
      />
      <div className="line"></div>
      <Step dataTarget="#resultDiv" stepperNumber="3" stepperText="Result" />
    </div>
    <div className="bs-stepper-content">
      <StepContent
        divId="specsDiv"
        content={
          <>
            <div className="create-cluster-fields ">
              <div className="resource-info-card">
                <CCard
                  textColor="white"
                  color="dark"
                  className="mb-3"
                  style={{
                    maxWidth: "25rem",
                  }}
                >
                  <CCardBody>
                    <CCardTitle>Account resources capacity</CCardTitle>
                    <CCardText>
                      {ClusterName.value && (
                        <div className="d-flex justify-content-end">
                          Used :
                          <p className="px-2">
                            {currentAccountUsed.sizes.CPU} CPU
                          </p>
                          <p className="px-2">
                            {currentAccountUsed.sizes.RAM}GB RAM
                          </p>
                          <p className="px-2">
                            {currentAccountUsed.sizes.STORAGE}GB HDD
                          </p>
                        </div>
                      )}

                      {Object.keys(resources)
                        .filter((plan) => plan === userData.currentAccount.plan)
                        .map((plan) => (
                          <div className="d-flex justify-content-end">
                            <div className=" d-flex justify-content-end">
                              Total :
                              <p className="px-2">
                                {resources[plan]["CPU"]} CPU
                              </p>
                              <p className="px-2">
                                {resources[plan]["RAM"]}GB RAM
                              </p>
                              <p className="px-2">
                                {resources[plan]["HDD"]}GB HDD
                              </p>
                            </div>
                          </div>
                        ))}
                    </CCardText>
                  </CCardBody>
                </CCard>
              </div>
              <div className="create-cluster-form">
                <CForm>
                  <Field>
                    <Input
                      type="text"
                      id="newClusterName"
                      name="new-cluster-name"
                      placeholder="* Name of the cluster"
                      maxLen={100}
                      required={true}
                      changeHandler={setClusterName}
                      validRegex="^[A-Za-z0-9][A-Za-z0-9_]*$"
                    />
                  </Field>
                  <Field>
                    <Input
                      type="text"
                      id="newClusterDesc"
                      name="new-cluster-desc"
                      placeholder="Description"
                      maxLen={300}
                      required={false}
                      changeHandler={setClusterDescription}
                    />
                  </Field>
                  <Field>
                    <CLabel htmlFor="selectVersion">K3S Version :</CLabel>
                    <CSelect
                      id="selectVersion"
                      defaultValue={
                        CLUSTER_VERSIONS.find((x) => x.default).value
                      }
                    >
                      {CLUSTER_VERSIONS.map((version, i) => {
                        return (
                          <option key={i} value={version.value}>
                            {version.value}
                          </option>
                        );
                      })}
                    </CSelect>
                  </Field>
                  <Field>
                    <CLabel>Auto-Expire In :</CLabel>
                    <CDropdown variant="btn-group" className="dropdownExpiry">
                      <CDropdownToggle>{expiryOption}</CDropdownToggle>
                      <CDropdownMenu>
                        {CURRENT_PLAN_EXPIRY_OPTIONS.map((option, i) => {
                          //  show the options in 2 columns
                          if (i % 2 === 0) {
                            return (
                              <div className="d-flex" key={i}>
                                <CDropdownItem
                                  key={i}
                                  href="#"
                                  onClick={() => setExpiryOption(option.label)}
                                >
                                  {option.label}
                                </CDropdownItem>
                                {i < CURRENT_PLAN_EXPIRY_OPTIONS.length - 1 && (
                                  <CDropdownItem
                                    key={i + 1}
                                    href="#"
                                    onClick={() =>
                                      setExpiryOption(
                                        CURRENT_PLAN_EXPIRY_OPTIONS[i + 1].label
                                      )
                                    }
                                  >
                                    {CURRENT_PLAN_EXPIRY_OPTIONS[i + 1].label}
                                  </CDropdownItem>
                                )}
                              </div>
                            );
                          }
                        })}
                      </CDropdownMenu>
                    </CDropdown>
                  </Field>
                </CForm>
              </div>
            </div>
            <h5 className="sizeLabel">Select Size</h5>
            <SpecsButtonGroup
              clusterSizeIndex={clusterSizeIndex}
              setClusterSizeIndex={setClusterSizeIndex}
              clusterResource={clusterResource}
              currentAccountUsed={currentAccountUsed}
              ClusterName={ClusterName}
            />
          </>
        }
        ClusterName={ClusterName}
        nextFunction={moveNextStep}
        previousFunction={movePreviousStep}
        showNext={true}
        calledFrom={"newCluster"}
        clusterSizeIndex={clusterSizeIndex}
        newClusterID={newClusterID}
      />
      <StepContent
        divId="featuresDiv"
        content={
          <Features
            selectedFeatures={selectedFeatures}
            selectedFeatureInputs={selectedFeatureInputs}
            formValues={formValues}
            toggleFeatureSelection={toggleFeatureSelection}
            existedFeatures={[]}
          />
        }
        previousFunction={movePreviousStep}
        showForm={true}
        handleFormSubmit={handleFormSubmit}
        ClusterName={ClusterName}
        ClusterDescription={ClusterDescription}
        inSubmit={inSubmit}
        currentStep={currentStep}
        showPrevious={true}
        calledFrom={"newCluster"}
        newClusterID={newClusterID}
      />
      <StepContent
        divId="resultDiv"
        content={
          (newClusterID || showQuotaLimit.status || error.status) && (
            <Result
              newClusterID={newClusterID}
              showQuotaLimit={showQuotaLimit}
              error={error}
              expiryOption={expiryOption}
            />
          )
        }
        doneFunction={handleCloseForm}
        previousFunction={movePreviousStep}
        isLast={true}
        calledFrom={"newCluster"}
        newClusterID={newClusterID}
      />
    </div>
  </div>
);

export default NewCluster;
